<script lang="ts">
import { defineAsyncComponent } from "vue";
import IconArrowLeft from "@/components/icons/IconArrowLeft.vue";
import IconExternal from "@/components/icons/IconExternal.vue";

import ArticleListContainer from "@/components/List/ListContainer/ArticleListContainer.vue";
import ArticleListItem from "@/components/List/ListItem/ArticleListItem.vue";
import ArticleListActionItem from "@/components/List/ListItem/ArticleListActionItem.vue";
import InputWrapper from "@/components/Input/InputWrapper.vue";

import ChangeEmailPopup from "@/components/Popup/account/ChangeEmailPopup.vue";

import {
  useBusinessUsersStore,
  useAccountStore,
  useBusinessConnectionStore,
  useBusinessCardsStore,
  useCardsStore,
  useAuthStore,
} from "@/stores";

export default {
  data() {
    return {
      isCardModalVisible: false,
      isOwnUserAccount: false,
      isChangeEmailPopupVisible: false,
      userId: "",
      businessId: "",
      businessUsersStore: useBusinessUsersStore(),
      businessCardsStore: useBusinessCardsStore(),
      businessConnectionsStore: useBusinessConnectionStore(),
      accountStore: useAccountStore(),
      authStore: useAuthStore(),
      cardsStore: useCardsStore(),
      user: {},
      userConnections: [],
      userCards: [],
      cardImageUrls: [],
      availableRoles: [
        {
          name: "Editor",
          code: "editor",
        },
        {
          name: "Viewer",
          code: "viewer",
        },
        {
          name: "Administrator",
          code: "admin",
        },
      ],
      isUserNotFound: false,
      isbusinessAdminRoutes:false
    };
  },
  components: {
    IconArrowLeft,
    IconExternal,
    ArticleListContainer,
    ArticleListItem,
    ArticleListActionItem,
    ChangeEmailPopup,
    InputWrapper,
    PVDropdown: defineAsyncComponent(() => import("primevue/dropdown")),
  },
  async created() {
    try {
      this.userId = this.getUserId();

      // Fetch business user
      this.user = await this.fetchUserById(this.userId);
      if (!this.user) {
        throw new Error("User not found");
      }

      // Check if viewing own user account
      this.isOwnUserAccount = this.checkIfOwnUserAccount(this.user.accountId);

      const connections = this.user.connections;
      const cards = this.user.cards;

      this.userConnections = connections;
      this.userCards = cards;

      this.fetchCardImageUrls();
      this.isbusinessAdminRoutes= localStorage.getItem("isbusinessAdminRoutes");

    } catch (error) {
      console.error(error);
      if (error.message === "User not found") {
        this.isUserNotFound = true;
      }
      // Handle other errors or log them if necessary
    }
  },
  methods: {
    async fetchCardImageUrls() {
      if (this.userCards) {
        for (const card of this.userCards) {
          const imageUrl = await this.cardsStore.getCardImage(card.cardType);
          this.cardImageUrls.push(imageUrl);
        }
      }
    },
    copyUserId() {
      navigator.clipboard.writeText(this?.user.userID);
      this.$toast.add({
        severity: "success",
        summary: "Copied",
        detail: `User ID ${this.user.userID} is copied to your clipboard.`,
        life: 5000,
      });
    },
    copyAccountEmail() {
      navigator.clipboard.writeText(this?.user.email);
      this.$toast.add({
        severity: "success",
        summary: "Copied",
        detail: `Email ${this.user.email} is copied to your clipboard.`,
        life: 5000,
      });
    },
    downloadQR() {

      const imageUrl = `https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=https://connectflow.app/p/${this.user.userID}`;
      const imageName = `${this.user.fname}_${this.user.lname}_${this.user.userID}`;

      fetch(imageUrl)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = imageName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        })
        .catch(error => {
          console.error('Error downloading image:', error);
        });
  


    },
    getUserId(): string {
      const rawUserId = this.$route.params.userId;

      if (Array.isArray(rawUserId)) {
        console.error("Unexpected array for pageId:", rawUserId);
        return "";
      }

      return rawUserId.toString();
    },
    async fetchUserById(userId: string) {
      return await this.businessUsersStore.getBusinessUser(userId);
    },

    checkIfOwnUserAccount(accountId: string) {
      const localAccountId = localStorage.getItem("accountId");
      return localAccountId === accountId;
    },
    async updateSetting(event: Event) {
      const input = event.target as HTMLInputElement;
      const labelName = input.previousSibling?.innerHTML;
      try {
        const value = input.value;
        const property = input.id;

        const updatedSetting = {
          [property]: value,
        };

        await this.businessUsersStore
          .updateBusinessUser(this.userId, updatedSetting)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Updated",
              detail: `${labelName} is updated to ${value}.`,
              life: 5000,
            });
          });
      } catch (error) {
        console.error("Error updating setting:", error);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: `Failed to update ${labelName}. Error: ${error.message}`,
          life: 5000,
        });
      }
    },
    async resetUserPassword() {
      this.authStore
        .forgotPassword(this.user.email)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: `Email is sent to ${this.user.fname}`,
            life: 5000,
          });
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Failed",
            detail: `Something went wrong: ${error}`,
            life: 3000,
          });
        });
    },
    async deleteUser() {
      this.$confirm.require({
        message: `Are you sure you want to delete this user?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          await this.unlinkCards();
          await this.businessUsersStore
            .deleteBusinessUser(this.userId)
            .then(async () => {
              this.$router.push("/business/users");
              this.$toast.add({
                severity: "success",
                summary: "Removed",
                detail: `User is removed`,
                life: 5000,
              });
            })
            .catch((error) => {
              this.$toast.add({
                severity: "error",
                summary: "Failed",
                detail: `Something went wrong: ${error}`,
                life: 3000,
              });
            });
        },
        reject: () => {
          // don't update it (does this happen automatically?)
        },
      });
    },
    async unlinkCards() {
      for (const card of this.userCards) {
        try {
          await this.cardsStore.deleteCard(card.uuid);
        } catch (error) {
          console.error(`Failed to delete card ${card.uuid}:`, error);
        }
      }
    },
    async updateUserRole() {
      const updatedSetting = {
        role: this.user.role,
      };

      this.$confirm.require({
        message: `Are you sure you want to update the role to ${this.user.role}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.businessUsersStore
            .updateBusinessUser(this.userId, updatedSetting)
            .then(() => {
              this.$toast.add({
                severity: "success",
                summary: "Updated",
                detail: `Role is updated to ${this.user.role}.`,
                life: 5000,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        },
        reject: () => {
          // don't update it (does this happen automatically?)
        },
      });
    },
    async updateVariable(input) {
      const value = input.target.value;
      const variableId = input.target.id;
      const labelName = input.target.previousSibling.innerHTML;

      const updatedVariable = {
        variablevalue: value,
      };

      try {
        await this.businessUsersStore
          .updateVariableValue(this.userId, variableId, updatedVariable)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Updated",
              detail: `${labelName} is updated to ${value}.`,
              life: 5000,
            });
          });
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: `Failed to update the field. Error: ${error.message}`,
          life: 5000,
        });
      }
    },
    onCloseChangeEmailPopup(isChangeEmailPopupVisible: boolean) {
      // Handle the emitted value here, if needed
      this.isChangeEmailPopupVisible = isChangeEmailPopupVisible;
    },
    async onProfilePictureUpload(event) {
      const file = await event.files[0];

      try {
        await this.businessUsersStore
          .uploadBusinessUserProfileImage(file, this.userId)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Uploaded",
              detail: "Your new profile picture is here",
              life: 5000,
            });
          })
          .then(() => {
            setTimeout(() => {
              location.reload();
            }, 2_000);
          });
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: `File failed to upload: ${error}`,
          life: 5000,
        });
      }
    },
    async createVirtualCard() {
      await this.businessCardsStore
        .createVirtualCardBusiness(this.userId)
        .then((data) => {
          this.$router.push(`/business/cards/card/${data.uuid}`);
          this.$toast.add({
            severity: "success",
            summary: "Succesful",
            detail: "Virtual card is created",
            life: 5000,
          });
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Failed",
            detail: `Virtual card failed to create: ${error}`,
            life: 5000,
          });
        });
    },
  },
};
</script>

<template>
  <PVPopup
    v-model:visible="isCardModalVisible"
    modal
    header="Get card"
    dismissableMask
    :draggable="false"
    :style="{ width: '80vw', maxWidth: '500px' }"
    class="add-card-modal"
  >
    <div class="modal-content">
      <a
        href="https://connectflow.io/products?utm_source=app&utm_medium=getcard"
        target="_blank"
        title="connectflow shop"
      >
        <article>
          <div class="image-container">
            <img src="@/assets/img/cards/physical-card-group.png" />
          </div>
          <div class="card-text">
            <h1>Physical</h1>
            <p>Get one of our cards with NFC and QR</p>
          </div>
        </article>
      </a>

      <button @click="createVirtualCard()">
        <article>
          <div class="image-container">
            <img src="@/assets/img/cards/virtual-card-group.png" />
          </div>
          <div class="card-text">
            <h1>Virtual</h1>
            <p>
              Get a virtual card that can be used in Apple Wallet and Google
              Wallet
            </p>
          </div>
        </article>
      </button>
    </div>
  </PVPopup>

  <ChangeEmailPopup
    v-if="isChangeEmailPopupVisible"
    @close-edit-dialog="onCloseChangeEmailPopup"
    :visible="isChangeEmailPopupVisible"
    :businessUserEmail="this.user.email"
    :businessUserId="this.userId"
  />
  <div v-if="this.isUserNotFound" class="container">
    <h1>User can't be found</h1>
    <router-link to="/business/users">Go back</router-link>
  </div>
  <div v-else class="container">
    <header>
      <router-link v-if="this.accountStore.isUserAdmin" to="/business/users">
        <IconArrowLeft />
      </router-link>
      <div v-else></div>
      <h1>{{ this.user.fname }}</h1>
      <a :href="`/p/${this.userId}`" target="_blank">
        <IconExternal />
      </a>
    </header>

    <img :src="this.user.image" class="profile-image" />

    <PVFileUpload
      class="btn btn-primary"
      mode="basic"
      name="demo[]"
      accept="image/*"
      :auto="true"
      chooseLabel="Upload image"
      @uploader="onProfilePictureUpload"
      customUpload
    />

    <!--
    <p class="account-mail">
      User ID: {{ this.user.userID }}<br />{{ this.user.email }}
    </p>
    -->

    <main>
      <section class="insights-container">
        <h1>Insights</h1>
        <div class="content">
          <p>
            {{ this?.user?.pageViewCount ?? 0 }}
            <span v-if="this?.user?.pageViewCount === 1">Share</span>
            <span v-else>Shares</span>
          </p>
          <p>
            {{ this?.user?.pageSaveCount ?? 0 }}
            <span v-if="this?.user?.pageSaveCount === 1">Save</span>
            <span v-else>Saves</span>
          </p>
          <p>
            {{ this?.user?.connections?.length ?? 0 }}
            <span v-if="this?.user?.connections?.length === 1">Connection</span>
            <span v-else>Connections</span>
          </p>
        </div>
      </section>
      <ArticleListContainer title="General" avatar="url" :hasStyling="false">
        <InputWrapper
          labelText="First name"
          labelRef="fname"
          v-model="this.user.fname"
          @change="updateSetting"
          isRequired
        />
        <InputWrapper
          labelText="Last name"
          labelRef="lname"
          v-model="this.user.lname"
          @change="updateSetting"
          isRequired
        />
        <InputWrapper
          labelText="Function"
          labelRef="functionTitle"
          v-model="this.user.functionTitle"
          @change="updateSetting"
          isRequired
        />
        <InputWrapper
          labelText="Bio"
          labelRef="bio"
          v-model="this.user.bio"
          @change="updateSetting"
          isRequired
        />
      </ArticleListContainer>
      <ArticleListContainer
        v-if="this?.user?.variables?.length > 0"
        title="Fields"
        avatar="url"
        :hasStyling="false"
      >
        <InputWrapper
          v-for="(variable, index) in this.user.variables"
          :key="index"
          :labelText="variable.variableName"
          :labelRef="variable.variableId"
          v-model="variable.variablevalue"
          placeholder=""
          @change="updateVariable"
        />
      </ArticleListContainer>
      <ArticleListContainer
        title="Connections"
        avatar="url"
        v-if="this.userConnections.length > 0"
      >
        <ArticleListItem
          v-for="connection in this.userConnections"
          :key="connection.connectId"
          :title="`${connection.fname} ${connection.lname}`"
          :subtitle="`${connection.company ?? ''}`"
          :isAvatar="true"
          :link="`/business/connections/connection/${connection.connectId}`"
        />
      </ArticleListContainer>
      <ArticleListContainer title="Cards" avatar="url">
        <ArticleListItem
          v-for="(card, index) in this.userCards"
          :key="card.id"
          :title="card?.cardDescription"
          :subtitle="card?.cardCategoryName"
          :isAvatar="false"
          :imageUrl="this.cardImageUrls[index]"
          :link="`/business/cards/card/${card.uuid}`"
        />
        <ArticleListItem
          title="Get card"
          subtitle="Get a new physical or virtual card"
          :isAvatar="false"
          icon="pi pi-plus"
          @click="isCardModalVisible = true"
        />
      </ArticleListContainer>
      <ArticleListContainer title="Actions" avatar="url">
        <!-- <ArticleListActionItem
          title="Card ID"
          :subtitle="card?.uuid"

          :isAvatar="false"
          icon="pi pi-hashtag"
          buttonIcon="pi pi-copy"
          buttonAriaLabel="Copy to clipboard"
          buttonClass="btn btn-icon"
          action="copyToClipboard"
        /> -->

        <ArticleListActionItem
          title="User ID"
          :subtitle="this.user.userID"
          :isAvatar="false"
          icon="pi pi-hashtag"
          @click="copyUserId"
        >
          <PVButton
            icon="pi pi-copy"
            aria-label="Copy to clipboard"
            class="btn btn-icon"
          />
        </ArticleListActionItem>

        <ArticleListActionItem
          title="QR Code"
          subtitle="Download QR code"
          :isAvatar="false"
          icon="pi pi-qrcode"
          @click="downloadQR"
        >
          <PVButton
            icon="pi pi-download"
            aria-label="Download QR"
            class="btn btn-icon"
          />
        </ArticleListActionItem>

        <ArticleListActionItem
          v-if="!isOwnUserAccount"
          title="Role"
          subtitle="Select a role"
          :isAvatar="false"
          icon="pi pi-link"
        >
          <PVDropdown
          v-if="this.isbusinessAdminRoutes"
            v-model="user.role"
            editable
            :options="this.availableRoles"
            optionLabel="name"
            optionValue="code"
            placeholder="Select role"
            @change="updateUserRole"
          />
          <PVDropdown
          v-else
            v-model="user.role"
            editable
            optionLabel="name"
            optionValue="code"
            placeholder="Select role"
            :disabled="true" 
          />
        </ArticleListActionItem>

        <ArticleListItem
          title="Reset password"
          :subtitle="`Send mail to ${this.user.fname}`"
          :isAvatar="false"
          icon="pi pi-unlock"
          @click="resetUserPassword"
        >
        </ArticleListItem>

        <ArticleListActionItem
          title="Account email"
          :subtitle="this.user.email"
          :isAvatar="false"
          icon="pi pi-envelope"
          @click="copyAccountEmail"
        >
          <PVButton
            icon="pi pi-copy"
            aria-label="Copy to clipboard"
            class="btn btn-icon"
          />
        </ArticleListActionItem>

        <ArticleListItem
          title="Change email"
          :subtitle="`Change email of ${this.user.fname}`"
          :isAvatar="false"
          :hasIcon="false"
          icon="pi pi-envelope"
          @click="isChangeEmailPopupVisible = true"
        />

        <ArticleListItem
          v-if="!isOwnUserAccount"
          title="Delete user"
          subtitle=""
          :isAvatar="false"
          icon="pi pi-trash"
          @click="deleteUser"
        >
        </ArticleListItem>
      </ArticleListContainer>
    </main>
  </div>
</template>

<style lang="scss" scoped>
main {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 90vw;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  animation: fade-in 0.8s cubic-bezier(0, 0, 0, 1) forwards;
}

section.insights-container {
  width: 100%;
  max-width: 600px;

  h1 {
    color: var(--color-text-grey);
    font-size: 12px;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 13px;
    top: -24px;
    padding-left: 10px;
    position: absolute;
  }

  .content {
    background-color: var(--color-white);
    width: 100%;
    height: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    transition: 0.2s;

    p {
      font-size: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 3px;
      align-items: center;
      padding-bottom: 0px;
      width: 100%;
      line-height: initial;

      span {
        color: var(--color-text-grey);
        font-weight: 500;
        font-size: 14px;
        display: block;
      }
    }
  }
}

.profile-image {
  margin: 32px 0px;
  background-color: var(--color-grey);
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
}

header a {
  color: var(--color-black);
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 100%;

  a,
  button {
    width: 100%;
    transition: 0.2s;
    text-decoration: none;
    color: inherit;
    border: none;
    padding: 0;
    text-align: left;
    font-size: 16px;
    height: 100%;

    &:hover {
      cursor: pointer;
    }

    a:hover {
      background-color: #222;
    }

    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  article {
    background-color: #f7f7f7;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    padding-top: 30px;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    row-gap: 30px;

    h1 {
      font-size: 22px;
      color: var(--color-black);
      margin-bottom: 2px;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      max-width: 320px;
      line-height: 1.3em;
      color: var(--color-text-grey);
    }

    .image-container {
      display: flex;

      img {
        width: 150px;
      }
    }

    .card-text {
      text-align: center;
      display: flex;
      row-gap: 5px;
      flex-direction: column;
    }
  }
}

.account-mail {
  color: var(--color-text-grey);
  font-size: 13px;
  font-weight: 600;
  display: flex;
  text-align: center;
  justify-content: center;
  line-height: normal;
  margin-top: 20px;
}
</style>
